import { Suspense } from 'react';

import { CategorySortField } from 'Component/CategorySort/CategorySort.type';
import { SORT_ICON } from 'Component/Common/Common.config';
import Image from 'Component/Image';
import {
    CategoryFilterOverlay,
    CategoryPageComponent as SourceCategoryPageComponent,
    CategorySort,
    FilterIcon,
    GridIcon,
    ListIcon,
} from 'SourceRoute/CategoryPage/CategoryPage.component';
import { ReactElement } from 'Type/Common.type';
import { isCrawler, isSSR } from 'Util/Browser';

import './CategoryPage.override.style';

export {
    CategoryFilterOverlay,
    FilterIcon,
    GridIcon,
    ListIcon,
    CategorySort,
};

/** @namespace Pwa/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPageComponent {
    renderMiscellaneous(): ReactElement {
        return (
            <aside block="CategoryPage" elem="Miscellaneous">
                { this.renderItemsCount() }
                <div
                  block="CategoryPage"
                  elem="MiscellaneousLayoutWrapper"
                >
                  <div
                    block="CategoryPage"
                    elem="LayoutWrapper"
                    mods={ { isPrerendered: isSSR() || isCrawler() } }
                  >
                      { /* { this.renderLayoutButtons() } */ }
                      { this.renderCategorySort() }
                  </div>
                  <div
                    block="CategoryPage"
                    elem="LayoutWrapper"
                    mods={ { isPrerendered: isSSR() || isCrawler() } }
                  >
                      { this.renderFilterButton() }
                  </div>
                </div>
            </aside>
        );
    }

    renderCategorySort(): ReactElement {
        const {
            sortFields,
            selectedSort,
            onSortChange,
            isMatchingInfoFilter,
            isCurrentCategoryLoaded,
            isMobile,
        } = this.props;

        const { options = [] } = sortFields;
        const updatedSortFields: CategorySortField[] = options.map(({ value: id, label }) => ({ id, label }));
        const { sortDirection, sortKey } = selectedSort;

        if (isMobile && !isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        return (
          <Suspense fallback={ <div block="CategoryPage" elem="CategorySortPlaceholder" /> }>
              <div block="CategoryPage" elem="SortBy">
                <Image src={ SORT_ICON } isPlain />
                { __('Sort by') }
              </div>
              <CategorySort
                isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                isMatchingInfoFilter={ isMatchingInfoFilter }
                onSortChange={ onSortChange }
                sortFields={ updatedSortFields }
                sortKey={ sortKey }
                sortDirection={ sortDirection }
              />
          </Suspense>
        );
    }
}

export default CategoryPageComponent;
